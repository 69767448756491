import './Portada.css';
import { Link } from 'react-scroll'

function Portada() {
  return (
    <div className="Portada" style={{ background: 'linear-gradient(rgba(0,0,0,.4), rgba(0,0,0,.4)), no-repeat center/cover url(' + process.env.PUBLIC_URL + '/imgs/portada.png)' }}>
      <h2>ENCONTRAR UN GASISTA MATRICULADO</h2>
      <p>Ya no es un problema</p>
      <Link className="Portada-link" smooth={true} duration={1500} to="Contactanos">¡Contactanos!</Link>
    </div>
  );
}

export default Portada;