import './Nosotros.css';

function Nosotros() {
  return (
    <div className="Nosotros">
      <h2>NOSOTROS</h2>
      <p>Somos una empresa con más de 10 años de experiencia en el rubro, todos nuestros técnicos son matriculados.<br /><br />Tranquilo… Sabemos lo difícil que es encontrar un profesional de confianza, es por eso que brindamos un servicio diferente.</p>
    </div>
  );
}

export default Nosotros;