import './Contactanos.css';
import { useState } from 'react';
import addGclid from './gclid'

function Contactanos() {

  const [showModal, setShowModal] = useState(false)

  const [nombre, setNombre] = useState('')
  const [telefono, setTelefono] = useState('')
  const [consulta, setConsulta] = useState('')

  //On form submit
  const submitForm = e => {
    e.preventDefault()
    if (nombre !== '' && telefono !== '' && consulta !== '') {
      setShowModal(true)
      const scrollY = window.scrollY
      document.body.style.position = 'fixed'
      document.body.style.top = '-' + scrollY + 'px'

      const waraForm = {
        "nombre": nombre,
        "telefono": telefono,
        "consulta": consulta,
        "gclid": addGclid()
      };

      const jsonData = JSON.stringify(waraForm);

      let integromatRequest = new XMLHttpRequest();
      integromatRequest.open('POST', 'https://hook.us1.make.com/k3ed1har9oq8lqao6rb1g7uovfn3j3c4');

      integromatRequest.onreadystatechange = function () {
        if (integromatRequest.readyState === 4) {
          if (integromatRequest.status === 200) {
            alert("Formulario enviado con éxito. Lo contactaremos a la brevedad.")
          } else {
            alert("Error al enviar el formulario, por favor vuelva a intentarlo o comuníquese por otra vía.")
          }
          setShowModal(false)
          const scrollY = document.body.style.top;
          document.body.style.position = '';
          document.body.style.top = '';
          window.scrollTo(0, parseInt(scrollY || '0') * -1);
        }
      };

      let newsletterserviceRequest = new XMLHttpRequest()
      newsletterserviceRequest.open('POST', 'https://newsletterservice.com.ar/landing/api/aguirregasweb')
      newsletterserviceRequest.setRequestHeader('Content-Type', 'application/json');
      newsletterserviceRequest.send(jsonData);

      integromatRequest.setRequestHeader('Content-Type', 'application/json');
      integromatRequest.send(jsonData);

    } else {
      alert('Por favor complete todos los campos antes de enviar el formulario.')
    }
  }

  return (
    <div className="Contactanos">
      <h2>CONTACTANOS</h2>


      <form className="Contactanos-form" onSubmit={submitForm}>
        <input type="text" placeholder="Nombre" onChange={e => setNombre(e.target.value)} value={nombre}></input>
        <input type="tel" placeholder="Teléfono" onChange={e => setTelefono(e.target.value)} value={telefono}></input>
        <textarea placeholder="Consulta" onChange={e => setConsulta(e.target.value)} value={consulta}></textarea>
        <input className="Contactanos-submit" type="submit" value="Enviar"></input>
      </form>


      {showModal ? <div className="Contactanos-modal">
        <img src={process.env.PUBLIC_URL + "/imgs/cargando.gif"} alt="Cargando..." />
        <p>Enviando el formulario, por favor aguarde</p>
      </div> : null}



    </div>
  );
}

export default Contactanos;