import './Navbar.css';
import { Link } from 'react-scroll'
import { useEffect, useState } from 'react';

function Navbar() {

  const [smallScreen, setSmallScreen] = useState(window.innerWidth <= 800)
  const [menuOpen, setMenuOpen] = useState(false)

  useEffect(() => {
    window.addEventListener('resize', () => setSmallScreen(window.innerWidth <= 800))
    return () => window.removeEventListener('resize', () => setSmallScreen(window.innerWidth <= 800))
  }, [])

  return (
    <div className="Navbar" style={{ height: menuOpen ? '250px' : '70px' }}>
      <div className="Navbar-container">
        <div className="Navbar-top">
          <img className="Navbar-logo" alt="Aguirre Gas" src={process.env.PUBLIC_URL + '/imgs/logo.png'} />
          {smallScreen ? <button className="Navbar-hamb" onClick={() => setMenuOpen(prevMenuOpen => !prevMenuOpen)}><img src={process.env.PUBLIC_URL + '/imgs/menu.svg'} alt="Toggle menu" /></button> : null}
        </div>
        <div className="Navbar-buttons">
          <Link className="Navbar-link" smooth={true} duration={1500} to="Nosotros" onClick={() => setMenuOpen(false)}>Nosotros</Link>
          <Link className="Navbar-link" smooth={true} duration={1500} to="Servicios" onClick={() => setMenuOpen(false)}>Servicios</Link>
          <Link className="Navbar-link" smooth={true} duration={1500} to="Trabajos" onClick={() => setMenuOpen(false)}>Trabajos</Link>
          <Link className="Navbar-link" smooth={true} duration={1500} to="Contactanos" onClick={() => setMenuOpen(false)}>Contacto</Link>
        </div>
      </div>
    </div>
  );
}

export default Navbar;