import './Servicios.css';
import { Link } from 'react-scroll'

function Servicios() {
  return (
    <div className="Servicios">
      <h2>NUESTROS SERVICIOS</h2>
      <div className="Servicios-elements">
        <div className="Servicios-element">
          <img src={process.env.PUBLIC_URL + '/imgs/medidor.svg'} alt="" />
          <p>Instalación de cañerías nuevas (Epoxi/Thermofusion)</p>
          <Link className="Servicios-element-link" smooth={true} duration={1500} to="Contactanos">¡Contactanos!</Link>
        </div>
        <div className="Servicios-element">
          <img src={process.env.PUBLIC_URL + '/imgs/tramite.svg'} alt="" />
          <p>Tramite de habilitación del servicio</p>
          <Link className="Servicios-element-link" smooth={true} duration={1500} to="Contactanos">¡Contactanos!</Link>
        </div>
        <div className="Servicios-element">
          <img src={process.env.PUBLIC_URL + '/imgs/deteccion.svg'} alt="" />
          <p>Detección de fugas</p>
          <Link className="Servicios-element-link" smooth={true} duration={1500} to="Contactanos">¡Contactanos!</Link>
        </div>
        <div className="Servicios-element">
          <img src={process.env.PUBLIC_URL + '/imgs/cocinas.svg'} alt="" />
          <p>Colocación de artefactos (Cocinas, estufas y termotanques) con firma de garantía</p>
          <Link className="Servicios-element-link" smooth={true} duration={1500} to="Contactanos">¡Contactanos!</Link>
        </div>
      </div>
    </div>
  );
}

export default Servicios;