import './Trabajos.css';
import { useEffect, useState } from 'react';

function Trabajos() {

  const picsAmount = 4;
  const elementWidth = 260;
  const elementMargins = 20;
  const elements = [];

  const [firstShown, setFirstShown] = useState(0)
  const [amountShown, setAmountShown] = useState(Math.trunc((window.innerWidth - elementMargins) / elementWidth) > picsAmount ? picsAmount : Math.trunc((window.innerWidth - elementMargins) / elementWidth))
  const [selectedPic, setSelectedPic] = useState(1)
  const [showModal, setShowModal] = useState(false)

  const onImageClick = index => {
    setSelectedPic(index)
    setShowModal(true)
    const scrollY = window.scrollY
    document.body.style.position = 'fixed'
    document.body.style.top = '-' + scrollY + 'px'
  }

  const onModalClose = () => {
    setShowModal(false)
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  }

  for (let i = 1; i <= picsAmount; i++) {
    elements.push(<div className="Trabajos-element" style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/imgs/' + i + '.png)' }} key={i} onClick={() => onImageClick(i)}></div>)
  }

  useEffect(() => {
    window.addEventListener('resize', () => setAmountShown(Math.trunc((window.innerWidth - elementMargins) / elementWidth) > picsAmount ? picsAmount : Math.trunc((window.innerWidth - elementMargins) / elementWidth)))
    return () => window.removeEventListener('resize', () => setAmountShown(Math.trunc((window.innerWidth - elementMargins) / elementWidth) > picsAmount ? picsAmount : Math.trunc((window.innerWidth - elementMargins) / elementWidth)))
  }, [])

  return (
    <div className="Trabajos">
      <h2>TRABAJOS REALIZADOS</h2>
      <div className="Trabajos-content" style={{ width: amountShown * elementWidth + 'px' }} >
        {firstShown > 0 ? <button style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/imgs/flecha.svg)' }} className="Trabajos-arrow-left" onClick={() => setFirstShown(prevFirstShown => prevFirstShown - 1)}></button> : null}
        <div className="Trabajos-elements" style={{ marginLeft: (firstShown * -1 * elementWidth) + 'px' }} >
          {elements}
        </div>
        {firstShown + amountShown < picsAmount ? <button style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/imgs/flecha.svg)' }} className="Trabajos-arrow-right" onClick={() => setFirstShown(prevFirstShown => prevFirstShown + 1)}></button> : null}
      </div>

      {showModal ?
        <div className="Trabajos-modal" onClick={() => onModalClose()}>
          <button className="Trabajos-modal-close"><img src={process.env.PUBLIC_URL + '/imgs/cerrar.svg'} alt="" /></button>
          <img className="Trabajos-modal-img" src={process.env.PUBLIC_URL + '/imgs/' + selectedPic + '.png'} alt="" />
        </div>
        : null}

    </div>
  );
}

export default Trabajos;