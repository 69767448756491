import './App.css';
import Navbar from './components/navbar/Navbar';
import Portada from './components/portada/Portada';
import Nosotros from './components/nosotros/Nosotros';
import Servicios from './components/servicios/Servicios';
import Trabajos from './components/trabajos/Trabajos';
import Contactanos from './components/contactanos/Contactanos';

function App() {

  const onWAClick = () => {
    window.gtag('event', 'conversion', {'send_to': 'AW-438807253/ilnuCPvvkPYBENXVntEB'})
    window.gtag('event', 'enviar', {'event_category': 'whatsapp', 'event_label': ''})
  }

  return (
    <div className="App">
      <Navbar />
      <Portada />
      <Nosotros />
      <Servicios />
      <Trabajos />
      <Contactanos />
      <footer className="App-footer">
        <div className="App-footer-container">
          <img className="App-footer-logo" src={process.env.PUBLIC_URL + '/imgs/logo.svg'} alt="Aguirre Gas" />
          <a className="App-footer-element" href="https://www.facebook.com/aguirregas" target="_blank" rel="noreferrer"><img src={process.env.PUBLIC_URL + '/imgs/fb.svg'} alt="Facebook" /></a>
          <a className="App-footer-element" href="https://www.instagram.com/aguirre_gasista" target="_blank" rel="noreferrer"><img src={process.env.PUBLIC_URL + '/imgs/ig.svg'} alt="Instagram" /></a>
        </div>
      </footer>

      <a href="https://api.whatsapp.com/send/?phone=5491131000172&text=¡Hola, quiero hablar con un gasista matriculado!" target="_blank" rel="noreferrer" className="App-wa" onClick={onWAClick}><img src={process.env.PUBLIC_URL + '/imgs/wa.svg'} alt="WhatsApp" /></a>
    </div>
  );
}

export default App;